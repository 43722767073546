import React from "react";
import { graphql } from "gatsby";

import DownloadPage from "@components/DownloadPage";

const Download = ({ data }) => {
  const { pageData } = data;

  return <DownloadPage {...pageData} />;
};

export const query = graphql`
  query DownloadPageQuery {
    pageData: sanityDownloadPage {
      title
        intro {
            ...SanityTextBoxFragment
        }
        backgroundImage {
            ...SanityBackgroundImageFragment
        }
        downloadBlock {
          content {
            ...SanityTextBoxFragment
          }
          backgroundColor {
            value
          }
          quote {
            author
            text
          }
        }
      appStoreUrl
      googlePlayUrl
    }
  }
`;

export default Download;
