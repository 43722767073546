import React from "react";

import TextBox from "@components/TextBox";
import Quote from "@components/Quote";
import Section from "@components/Section";
import Video from "@components/Video";

import * as styles from "./styles.module.css";

const DownloadBlock = ({ content, backgroundColor, quote }) => {
    return (
        <Section
        as="section"
        className={styles.container}
        backgroundColor={backgroundColor?.value}
        >
            <Video embedId="WhecOkfvzqQ" />
            <Quote className={styles.quoteBox} {...quote} />

            <TextBox {...content} whiteBox={false} className={styles.contentBox} />
        </Section>
    );
};

export default DownloadBlock;