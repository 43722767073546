import React from "react";
import PropTypes from "prop-types";


//import * as styles from "./styles.module.css";

import styles from "./styles.css";

const Video = ({ embedId }) => {
    return (
        <>
            <div className="videoResponsive">
                <iframe
                className="videoResponsiveIframe"
                width="350"
                height="250"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
        </>
    );
    Video.propTypes = {
        embedId: PropTypes.string.isRequired
      };
}

export default Video;