import React from "react";

import Hero from "@components/Hero";
import Section from "@components/Section";
import TextBox from "@components/TextBox";
import DownloadBlock from "@components/DownloadBlock";
import { ReactComponent as AppStore } from "@assets/appStore.svg";
import { ReactComponent as GooglePlay } from "@assets/googlePlay.svg";

import * as styles from "./styles.module.css";

const DownloadPage = ({ 
  googlePlayUrl, 
  appStoreUrl,
  title,
  backgroundImage,
  intro,
  downloadBlock }) => {
  return (
    <>
    
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
        >
          <TextBox {...intro} className={styles.box} />
      </Hero>
      <Section as="section" className={styles.container}>
        <a
          className={styles.button}
          href={googlePlayUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <GooglePlay />
        </a>
        <a
          className={styles.button}
          href={appStoreUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <AppStore />
        </a>
      </Section>
      <DownloadBlock {...downloadBlock } />
      
    </>
  );
};

export default DownloadPage;
